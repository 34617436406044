import React, { useState, useEffect, useRef } from "react"
import * as THREE from "three"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const Scene1 = () => {

  const containerRef = useRef(null);
  let sceneRef = useRef(null);
  let cameraRef = useRef(null);
  let rendererRef = useRef(null);
  let meshRef = useRef(null);

  const [scrollRotation, setScrollRotation] = useState(0);

  const vertexShader = `varying vec2 vUv;

    void main()
    {
      vUv = uv;
      vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
      gl_Position = projectionMatrix * mvPosition;
    }`;

  const fragmentShader = `uniform vec2 resolution;
    varying vec2 vUv;
    uniform float time;
    uniform float offset;
    uniform float timeoffset;
    const float radius_out = 1.0;
    const float radius_in = .0275;
    
    const lowp float speed = 3.;
    const lowp vec3 cycle = vec3(.2523/3.,.9239745/3.,.43192/3.);
    const lowp vec3 col_base = vec3(0.4,0.4,1.);
    varying lowp vec3 col_base_cycle;
    varying lowp vec3 col_process;
    varying lowp vec3 col_blend;
    varying lowp vec3 col_final;

    float circle(in vec2 _st,in float _radius){
      vec2 dist=_st-vec2(.5);
      return 1.-smoothstep(_radius,
      _radius+.001,
      dot(dist,dist)*4.);
    }
    
    float blendOverlay(float base,float blend){
      return(blend==1.)?blend:min(base/(1.-blend),1.);
    }

    void main(){
      vec2 st=gl_FragCoord.xy/resolution.xy;
      st.x*=resolution.x/resolution.y;

      float t = timeoffset + time;
      
      vec3 col_base_cycle = vec3(sin(t*cycle.x)+.4,sin(t*cycle.y)+.4,sin(t*cycle.z)+1.);

      vec2 p = offset + 2.0 * vUv;

      vec2 dist=st-vec2(.5);
      float m = t/speed;
      
      for(int i=1;i<4;i++)
      {
        vec2 newp=p;
        newp.x+=3./float(i)*sin(float(i)*p.y+m);
        newp.y+=1./float(i)*cos(float(i)*p.x+m);
        p=newp;
      }

      vec3 col_process = vec3(sin(p.x)+.4,sin(p.y)+.4,sin(p.x+p.y));
      
      vec3 col_blend=vec3(blendOverlay(col_base_cycle.r,col_process.r),blendOverlay(col_base_cycle.g,col_process.g),blendOverlay(col_base_cycle.b,col_process.b));
      
      vec3 color = vec3(p,1.0);
      gl_FragColor = vec4(col_blend,1.0);
    }`;

  const material = new THREE.ShaderMaterial({

    uniforms: {
      "time": { value: 1.0 },
      "offset": { value: 1.0 },
      "resolution": { value: new THREE.Vector2() }
    },
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,

  });

  /**
   * 
   * initial scene setup
   * 
   **/

  useEffect(() => {

    const { current } = containerRef;

    sceneRef.current = new THREE.Scene()
    cameraRef.current = new THREE.PerspectiveCamera(75, current.offsetWidth / current.offsetHeight, 0.1, 1000)

    rendererRef.current = new THREE.WebGLRenderer();
    rendererRef.current.setSize(current.offsetWidth, current.offsetHeight)
    current.appendChild(rendererRef.current.domElement)

    const geometry = new THREE.SphereGeometry(20, 60, 60);

    meshRef.current = new THREE.Mesh(geometry, material);
    sceneRef.current.add(meshRef.current)

    cameraRef.current.position.z = 50

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);


  /**
   * 
   * use scroll position to set rotation
   * 
   **/

  useScrollPosition(({ prevPos, currPos }) => {
    setScrollRotation(currPos.y * -0.005)
    meshRef.current.material.uniforms["offset"].value = 1 - scrollRotation;
    //meshRef.current.rotation.y = scrollRotation
    cameraRef.current.position.z = 50 - (scrollRotation * 20);
  }, [scrollRotation])


  /**
   * 
   * requestAnimationFrame
   * https://css-tricks.com/using-requestanimationframe-with-react-hooks/
   * 
   **/

  const requestRef = React.useRef();

  const animate = time => {

    // set uniform values directly on material
    meshRef.current.material.uniforms["time"].value = time * 0.001;
    rendererRef.current.render(sceneRef.current, cameraRef.current)
    requestRef.current = requestAnimationFrame(animate);

  }

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div style={{
      position: `fixed`,
      left: 0, right: 0, top: 0, bottom: 0,
      border: `1px solid red`,
      zIndex: -10,
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
    }}>
      <div style={{
        position: `fixed`
      }}>
        <code style={{ display: `block` }}>{scrollRotation}</code>
      </div>
      <div ref={containerRef} style={{ width: `100vw`, height: `100vh` }}></div>
    </div>
  )

}

export default Scene1