import React from "react"
//import { Link } from "gatsby"

import Seo from "../components/seo"
import Scene1 from "../components/scene1"

const ThreeJSPage = () => (
  <>
    <Seo title="ThreeJS" />

    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-half">
            <div className="content">
              
              <h1 className="underlined is-uppercase">ThreeJS</h1>

              <Scene1></Scene1>

              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce suscipit nunc sit amet leo porta sollicitudin. Ut non ultrices quam. Ut aliquet pulvinar mollis. Vivamus non ex interdum, eleifend ante ac, rhoncus libero. Etiam facilisis scelerisque arcu. Curabitur ut diam purus. In sed lacus tempus, auctor orci vel, eleifend nunc. Sed id blandit sem. Mauris vel ex eget magna porttitor rutrum sit amet ac lacus. Aliquam erat volutpat. Curabitur ex sem, varius quis massa vitae, aliquet euismod ante.</p>
              <p>Donec efficitur tortor leo, venenatis ullamcorper orci luctus non. Donec ut nisl nunc. Maecenas condimentum consectetur risus at faucibus. Quisque eu gravida dui, in tristique dolor. Morbi mattis molestie volutpat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mollis pulvinar rutrum. Curabitur sit amet tortor eros. Nam dictum nisi ligula, a pretium ipsum eleifend rutrum. Mauris pharetra nulla sit amet volutpat ultrices. Donec eu mauris non dolor convallis ultrices. Sed iaculis auctor tortor, euismod molestie sapien faucibus quis. Donec vehicula nunc ex, eu tincidunt est fringilla sed. Cras varius ante a blandit consequat. Morbi eleifend blandit nunc id porta.</p>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default ThreeJSPage
